
import { computed } from 'vue';
import { generalStore } from '@/store';
import { Product } from '@/models/Product';

export default {
    props: {
        currentStep: {
            type: Number
        },
        currentSubStep: {
            type: String
        }
    },
    setup(props: any) {
        const direction = computed<string>(() => {
            return generalStore.getters.currentLanguage == 'he-IL' ? 'right' : 'left';
        });
        const product = computed<Product>(() => generalStore.getters.product);
        const scaleX = computed<number>(() => {
            const stepWith = 0.5;
            let width = (props.currentStep - 1) * stepWith;
            if (props.currentStep == 1) {
                width += (stepWith * (props.currentSubStep - 1)) / 3;
            } else if (props.currentStep == 2) {
                width += (stepWith * (props.currentSubStep - 1)) / 4;
            } else if (props.currentStep == 3) {
                width = 1;
            }
            return width;
        });

        return {
            direction,
            scaleX,
            product
        };
    }
};
