
import { generalStore } from '@/store';
import { Product, ProductTags } from '@/models/Product';
import { computed } from 'vue';

export default {
    async setup() {
        const product = computed<Product>(() => generalStore.getters.getNewProduct);

        if (!generalStore.state.tags.length) await generalStore.dispatch('loadAllTags');
        const tags = generalStore.state.tags;

        const foodDrinkId = tags.find(t => t.name == 'Food and Drink')?.id;
        const foodDrinkTags = () => product.value.productTags.filter(t => t.parentTagId == foodDrinkId);
        function deleteFoodDrinkTag(tag: ProductTags) {
            product.value.productTags.splice(product.value.productTags.indexOf(tag), 1);
        }

        const religionId = tags.find(t => t.name == 'Religion')?.id;
        const religionTags = () => product.value.productTags.filter(t => t.parentTagId == religionId);
        function deleteReligionTag(tag: ProductTags) {
            product.value.productTags.splice(product.value.productTags.indexOf(tag), 1);
        }

        const languagesId = tags.find(t => t.name == 'Languages')?.id;
        const languagesTags = () => product.value.productTags.filter(t => t.parentTagId == languagesId);
        function deleteLanguagesTag(tag: ProductTags) {
            product.value.productTags.splice(product.value.productTags.indexOf(tag), 1);
        }

        return {
            product,
            foodDrinkId,
            foodDrinkTags,
            deleteFoodDrinkTag,
            religionId,
            religionTags,
            deleteReligionTag,
            languagesId,
            languagesTags,
            deleteLanguagesTag
        };
    }
};
