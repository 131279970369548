
import { generalStore } from '@/store';
import { Product, ProductTags } from '@/models/Product';
import Multiselect from '@vueform/multiselect';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
    components: {
        Multiselect
    },
    async setup() {
        const { t } = useI18n();

        const product = computed<Product>(() => generalStore.getters.getNewProduct);

        if (!generalStore.state.productTypes.length) await generalStore.dispatch('loadAllProductTypes');
        const productTypes = generalStore.state.productTypes;

        if (!generalStore.state.tags.length) await generalStore.dispatch('loadAllTags');
        const tags = generalStore.state.tags;

        const subTypesId = tags.find(t => t.name == 'Sub Type')?.id;
        const productSubTypes = () => product.value.productTags.filter(t => t.parentTagId == subTypesId);
        function deleteSubType(tag: ProductTags) {
            product.value.productTags.splice(product.value.productTags.indexOf(tag), 1);
        }

        const productTypeSelect = {
            placeholder: t('placeholder.selectProductType'),
            valueProp: 'id',
            trackBy: 'name',
            label: 'name',
            searchable: true,
            options: productTypes
        };

        return { product, productTypes, productTypeSelect, productSubTypes, deleteSubType, subTypesId };
    }
};
