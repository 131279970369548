
import '@/assets/createProductWizzard.css';
import { Product } from '@/models/Product';
import StepsNavigation from '@/components/CreateProductWizzard/StepsNavigation.vue';
import SubStepOne from '@/components/CreateProductWizzard/StepTwo/SubStepOne.vue';
import SubStepTwo from '@/components/CreateProductWizzard/StepTwo/SubStepTwo.vue';
import SubStepThree from '@/components/CreateProductWizzard/StepTwo/SubStepThree.vue';
import SubStepFour from '@/components/CreateProductWizzard/StepTwo/SubStepFour.vue';
import { generalStore } from '@/store';
import { computed } from 'vue';
import router from '@/router';
import useProduct from '@/modules/useProduct';

export default {
    props: {
        subStep: {
            type: String,
            required: true
        },
        productId: {
            type: String
        }
    },
    components: {
        StepsNavigation,
        SubStepOne,
        SubStepTwo,
        SubStepThree,
        SubStepFour
    },
    async setup(props: any) {
        const { saveProduct, getProduct, getWizzardStep } = useProduct();

        if (props.productId) await getProduct(props.productId);

        const product = computed<Product>(() => generalStore.getters.getNewProduct);

        const changeStep = (stepName: string, subStep: string) => {
            router.push({ name: stepName, params: { subStep: subStep, productId: product.value.id } });
        };

        const submitForm = async (
            event: any,
            nextStep: {
                stepName: string;
                subStep: string;
            }
        ) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                product.value.wizzardStep = getWizzardStep('createProductStepOne', props.subStep);
                const savedProduct = await saveProduct(product.value);
                if (savedProduct) {
                    router.push({ name: nextStep.stepName, params: { subStep: nextStep.subStep, productId: savedProduct.id } });
                }
            }
        };

        return {
            product,
            submitForm,
            changeStep
        };
    }
};
