
import { generalStore } from '@/store';
import { Product, ProductTags } from '@/models/Product';
import { computed } from 'vue';
import { ProductInfoType } from '@/models/Enums';
import { useI18n } from 'vue-i18n';

export default {
    async setup() {
        const { t } = useI18n();
        const product = computed<Product>(() => generalStore.getters.getNewProduct);
        if (!product.value.productInfos.find(x => x.type == ProductInfoType.General)) {
            product.value.productInfos.push({
                id: '',
                title: t('product-info.title.important'),
                description: '',
                type: ProductInfoType.General,
                translations: []
            });
        }

        if (!generalStore.state.tags.length) await generalStore.dispatch('loadAllTags');
        const tags = generalStore.state.tags;

        const includedExcludedId = tags.find(t => t.name == 'Included - Not Included')?.id;

        const includedTags = () => product.value.productIncludedTags.filter(t => t.parentTagId == includedExcludedId);
        function deleteIncludeTag(tag: ProductTags) {
            product.value.productIncludedTags.splice(product.value.productIncludedTags.indexOf(tag), 1);
        }

        const excludedTags = () => product.value.productExcludedTags.filter(t => t.parentTagId == includedExcludedId);
        function deleteExcludeTag(tag: ProductTags) {
            product.value.productExcludedTags.splice(product.value.productExcludedTags.indexOf(tag), 1);
        }

        return {
            product,
            includedExcludedId,
            includedTags,
            deleteIncludeTag,
            excludedTags,
            deleteExcludeTag
        };
    }
};
