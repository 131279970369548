
import { generalStore } from '@/store';
import { Product, ProductTags } from '@/models/Product';
import { computed } from 'vue';

export default {
    async setup() {
        const product = computed<Product>(() => generalStore.getters.getNewProduct);

        if (!generalStore.state.tags.length) await generalStore.dispatch('loadAllTags');
        const tags = generalStore.state.tags;

        const targetAudiencesId = tags.find(t => t.name == 'Target Audiences')?.id;
        const productTargetAudiencesTags = () => product.value.productTags.filter(t => t.parentTagId == targetAudiencesId);
        function deleteTargetAudiencesTag(tag: ProductTags) {
            product.value.productTags.splice(product.value.productTags.indexOf(tag), 1);
        }
        function deleteDifficultyLevelTags(tag: ProductTags) {
            product.value.productTags.splice(product.value.productTags.indexOf(tag), 1);
        }

        const difficultyLevelId = tags.find(t => t.name == 'Difficulty Level')?.id;
        const difficultyLevelTags = () => product.value.productTags.filter(t => t.parentTagId == difficultyLevelId);

        return {
            product,
            productTargetAudiencesTags,
            deleteTargetAudiencesTag,
            targetAudiencesId,
            difficultyLevelTags,
            deleteDifficultyLevelTags,
            difficultyLevelId
        };
    }
};
