import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "create-product-steps" }
const _hoisted_5 = { class: "product-name" }
const _hoisted_6 = { class: "container" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "step-container" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepsNavigation = _resolveComponent("StepsNavigation")
  const _component_SubStepOne = _resolveComponent("SubStepOne")
  const _component_SubStepTwo = _resolveComponent("SubStepTwo")
  const _component_SubStepThree = _resolveComponent("SubStepThree")
  const _component_SubStepFour = _resolveComponent("SubStepFour")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode("div", _hoisted_5, _toDisplayString($setup.product.name), 1),
          _createVNode("div", _hoisted_6, [
            _createVNode("div", _hoisted_7, [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_StepsNavigation, {
                    currentStep: 2,
                    currentSubStep: $props.subStep
                  }, null, 8, ["currentSubStep"])
                ]),
                _: 1
              }))
            ]),
            _createVNode("div", _hoisted_8, [
              _createVNode("div", _hoisted_9, [
                _createVNode("div", _hoisted_10, [
                  ($props.subStep == 1)
                    ? (_openBlock(), _createBlock("div", _hoisted_11, [
                        (_openBlock(), _createBlock(_Suspense, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_SubStepOne, { onFormSubmitted: $setup.submitForm }, null, 8, ["onFormSubmitted"])
                          ]),
                          _: 1
                        }))
                      ]))
                    : _createCommentVNode("", true),
                  ($props.subStep == 2)
                    ? (_openBlock(), _createBlock("div", _hoisted_12, [
                        (_openBlock(), _createBlock(_Suspense, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_SubStepTwo, { onFormSubmitted: $setup.submitForm }, null, 8, ["onFormSubmitted"])
                          ]),
                          _: 1
                        }))
                      ]))
                    : _createCommentVNode("", true),
                  ($props.subStep == 3)
                    ? (_openBlock(), _createBlock("div", _hoisted_13, [
                        (_openBlock(), _createBlock(_Suspense, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_SubStepThree, { onFormSubmitted: $setup.submitForm }, null, 8, ["onFormSubmitted"])
                          ]),
                          _: 1
                        }))
                      ]))
                    : _createCommentVNode("", true),
                  ($props.subStep == 4)
                    ? (_openBlock(), _createBlock("div", _hoisted_14, [
                        (_openBlock(), _createBlock(_Suspense, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_SubStepFour, { onFormSubmitted: $setup.submitForm }, null, 8, ["onFormSubmitted"])
                          ]),
                          _: 1
                        }))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}